import { Button, Space, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  DeleteOutlined,
  EditOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { getColumnSearchProps } from "../../common/table.common";

const mapDispatchToProps = (dispatch) => {
  return {};
};

class ConnectedKoneLiftsList extends Component {
  formRef = React.createRef();

  state = { searchText: "", searchedColumn: "" };

  componentDidMount = async () => {};

  handleOpenUpdateSetOfUsersModal = async (elevatorLevel) => {
    await this.props.handleOpenUpdateSetOfUsersModal(elevatorLevel);
  };

  handleOpenModifyElevatorLevelModal = (record) => {
    this.props.handleOpenModifyElevatorLevelModal(record);
  };

  handleDelete = async (elevatorLevelId) => {
    await this.props.handleDeleteElevatorLevel(elevatorLevelId);
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const columns = [
      {
        title: this.props.t("companyadmin-lift-name-label"),
        key: "name",
        dataIndex: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ["ascend", "descend"],
        ...getColumnSearchProps(
          "name",
          this.props.t("companyadmin-address-name-label"),
          this.state.searchText,
          this.state.searchedColumn,
          this.handleSearch,
          this.handleReset,
          this.formRef,
          this.props.t
        ),
      },
      {
        title: <UserAddOutlined />,
        key: "addUser",
        render: (text, data) => {
          return (
            <Space size="middle">
              <Button
                onClick={() => this.handleOpenUpdateSetOfUsersModal(data)}
                disabled={data.isPublic}
              >
                <UserAddOutlined />
              </Button>
            </Space>
          );
        },
      },

      {
        title: <EditOutlined />,
        key: "modify",
        render: (text, data) => {
          return (
            <Space size="middle">
              <Button onClick={() => this.handleOpenModifyElevatorLevelModal(data)}>
                <EditOutlined />
              </Button>
            </Space>
          );
        },
      },
      {
        title: <DeleteOutlined />,
        key: "delete",
        render: (text, data) => {
          return (
            <Space size="middle">
              <Button
                danger
                onClick={() => this.handleDelete(data.id)}
                disabled={false}
                type="primary"
              >
                <DeleteOutlined />
              </Button>
            </Space>
          );
        },
      },
    ];

    return (
      <>
        <Table
          columns={columns}
          dataSource={this.props.elevatorLevels}
          loading={this.props.loading}
          rowKey="id"
          locale={{ emptyText: "Nincs adat" }}
          pagination={
            this.props.elevatorLevels && this.props.elevatorLevels.length > 10
              ? {
                  position: ["bottomCenter"],
                  showSizeChanger: true,
                }
              : false
          }
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
});

const KoneLiftsList = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedKoneLiftsList)
);

export default KoneLiftsList;
