import { Button, Col, Row } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addUsersToElevatorLevel,
  deleteCompanyElevatorLevel,
  getElevatorLevelById,
  getAllCompaniesByOfficeId,
  modifyCompanyElevatorLevel,
  saveCompanyElevatorLevel,
} from "../../actions/company";
import { withTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { getAllUsers, getAllUsersByOffice } from "../../actions/users";
import { getAllOfficeElevatorLevels, getOneOfficeById } from "../../actions/office";
import KoneLiftsList from "../../components/KoneLifts/koneLifts";
import CreateOrUpdateKoneLevelModal from "../../components/KoneLifts/createOrUpdateKoneLevelModal";
import { isArray } from "lodash";
import UpdateSetOfUsersModal from "../../components/Users/UpdateSetOfUsersModal/updateSetOfUsersModal";
import { userRoles } from "../../constants/roles-types";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllOfficeElevatorLevels: (officeId) =>
      dispatch(getAllOfficeElevatorLevels(officeId)),
    saveCompanyElevatorLevel: (params) => dispatch(saveCompanyElevatorLevel(params)),
    modifyCompanyElevatorLevel: (params) => dispatch(modifyCompanyElevatorLevel(params)),
    deleteCompanyElevatorLevel: (id) => dispatch(deleteCompanyElevatorLevel(id)),
    getElevatorLevelById: (elevatorLevelId) => dispatch(getElevatorLevelById(elevatorLevelId)),
    getAllUsers: (groupIds, companyId) =>
      dispatch(getAllUsers(groupIds, companyId)),
    getAllUsersByOffice: (officeId) => dispatch(getAllUsersByOffice(officeId)),
    addUsersToElevatorLevel: (params) => dispatch(addUsersToElevatorLevel(params)),
    getAllCompaniesByOfficeId: (officeId) =>
      dispatch(getAllCompaniesByOfficeId(officeId)),
    getOneOfficeById: (officeId) => dispatch(getOneOfficeById(officeId)),
  };
};

class ConnectedKoneLiftsPage extends Component {
  formRef = React.createRef();

  state = {
    targetKeys: [],
    selectedKeys: [],
    companyName: "",
    baseTime: null,
    addressBaseTime: null,
    anonymizationTime: 30,
    officeAnonymizationTimeLimit: 30,
    companies: [],
    companyId: null,
    addCompany: false,

    createOrUpdateModalVisible: false,
    createOrUpdateModalMode: "create",
    selectedLevel: null,
    office: null,
    updateSetOfUsersModalVisible: false,
    addeddUsers: [],
    allUsers: [],
  };

  componentDidMount = async () => {
    let officeId = this.props.user.officeId;
    await this.props.getAllCompaniesByOfficeId(officeId);
    this.fetchOfficeElevatorLevels();
    await this.props.getOneOfficeById(this.props.user.officeId);
    if (this.props.officeStatus) {
      this.setState({
        office: isArray(this.props.office)
          ? this.props.office[0]
          : this.props.office,
      });
    }
  };

  fetchOfficeElevatorLevels = async () => {
    this.setState({ loadingLevels: true });
    await this.props.getAllOfficeElevatorLevels(this.props.user.officeId);
    this.setState({ loadingLevels: false });
  };

  handleOpenNewLevelModal = () => {
    this.setState({
      createOrUpdateModalVisible: true,
      createOrUpdateModalMode: "create",
    });
  };

  handleOpenModifyLevelModal = (record) => {
    this.setState({
      createOrUpdateModalVisible: true,
      createOrUpdateModalMode: "modify",
      selectedLevel: record,
    });
  };

  handleCloseCreateOrUpdateLevelModal = () => {
    this.setState({
      createOrUpdateModalVisible: false,
      createOrUpdateModalMode: "create",
      selectedLevel: null,
    });
  };

  handleCloseUpdateSetOfUsersModal = () => {
    this.setState({
      updateSetOfUsersModalVisible: false,
      allUsers: [],
      addeddUserIds: [],
    });
  };

  handleOpenUpdateSetOfUsersModal = async (level) => {
    if (level.companyId) {
      await this.props.getAllUsers([userRoles.USER], level.companyId);
    } else {
      await this.props.getAllUsersByOffice(level.officeId);
    }
    await this.props.getElevatorLevelById(level.id);
    if (this.props.status) {
      console.log(this.props.elevatorLevel);
      const target = this.props.elevatorLevel && this.props.elevatorLevel.users ? this.props.elevatorLevel.users.map((user) => user.id) : [];
      this.setState({
        updateSetOfUsersModalVisible: true,
        addeddUserIds: target,
        allUsers: this.props.users,
        levelId: level.id,
      });
    }
  };

  handleDeleteElevatorLevel = async (levelId) => {
    await this.props.deleteCompanyElevatorLevel(levelId);
    if (this.props.saveStatus) {
      this.fetchOfficeElevatorLevels();
    }
  };

  handleSaveSelectedUsers = async (params) => {
    params.elevatorLevelId = params.addressId;
    delete params.addressId;
    await this.props.addUsersToElevatorLevel(params);
    this.handleCloseUpdateSetOfUsersModal();
    await this.fetchOfficeElevatorLevels()
  };

  handleDelete = async (id) => {
    await this.props.deleteCompanyElevatorLevel(id);
    if (this.props.saveStatus) {
      await this.props.getAllOfficeElevatorLevels(
        this.props.user.officeId
          ? this.props.user.officeId
          : this.props.officeId
      );
    }
  };

  createNewLevel = async (levelData) => {
    await this.props.saveCompanyElevatorLevel(levelData);
    await this.fetchOfficeElevatorLevels();
  };

  updateLevel = async (levelData) => {
    await this.props.modifyCompanyElevatorLevel(levelData);
    await this.fetchOfficeElevatorLevels();
  };

  render() {
    return (
      <div style={{ overflowY: "auto" }}>
        <Row>
          <Col span={24} style={{ padding: "1em" }}>
            <Button type="primary" onClick={this.handleOpenNewLevelModal}>
              <PlusOutlined />
              {this.props.t("addresses-create-new-address-button-label")}
            </Button>
          </Col>
        </Row>

        <Row>
          <Col span={24} style={{ padding: "1em" }}>
            <KoneLiftsList
              loading={this.state.loadingLevels}
              elevatorLevels={this.props.elevatorLevels}
              handleOpenUpdateUsersToAddressModal={
                this.handleOpenUpdateUsersToAddressModal
              }
              handleOpenModifyElevatorLevelModal={this.handleOpenModifyLevelModal}
              handleDeleteElevatorLevel={this.handleDeleteElevatorLevel}
              handleOpenUpdateSetOfUsersModal={
                this.handleOpenUpdateSetOfUsersModal
              }
            />
          </Col>
        </Row>
        <CreateOrUpdateKoneLevelModal
          modalVisible={this.state.createOrUpdateModalVisible}
          modalMode={this.state.createOrUpdateModalMode}
          companies={this.props.companies}
          handleCloseCreateOrUpdateElevatorLevelModal={
            this.handleCloseCreateOrUpdateLevelModal
          }
          selectedLevel={this.state.selectedLevel}
          office={this.state.office}
          createNewLevel={this.createNewLevel}
          updateLevel={this.updateLevel}
          saveStatus={this.props.saveStatus}
        />
        <UpdateSetOfUsersModal
          modalVisible={this.state.updateSetOfUsersModalVisible}
          title="add-user-to-level-title"
          handleCloseUpdateSetOfUsersModal={
            this.handleCloseUpdateSetOfUsersModal
          }
          addeddUserIds={this.state.addeddUserIds}
          allUsers={this.state.allUsers}
          addressId={this.state.levelId}
          handleSaveSelectedUsers={this.handleSaveSelectedUsers}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  company: state.companyReducer.company,
  status: state.companyReducer.status,
  elevatorLevels: state.companyReducer.elevatorLevels,
  elevatorLevel: state.companyReducer.elevatorLevel,
  saveStatus: state.companyReducer.saveStatus,
  users: state.usersReducer.users,
  officeStatus: state.officeReducer.status,
  office: state.officeReducer.office,
  companies: state.companyReducer.companies,
});
const KoneLiftsPage = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedKoneLiftsPage)
);
export default KoneLiftsPage;
