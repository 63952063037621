import { message } from "antd";
import axios from "axios";
import { LANGUAGE_ACTIONS, OFFICE_ACTIONS, COMPANY_ACTIONS } from "../constants/action-types";
import Axios from "axios"

export const getAllOffices = () => {
  return async (dispatch) => {
    dispatch({ type: OFFICE_ACTIONS.OFFICES_LOADING });
    await axios
      .get("/2.0.0/office", {
      })
      .then((response) => {
        dispatch({
          type: OFFICE_ACTIONS.OFFICES_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: OFFICE_ACTIONS.OFFICES_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const saveOffice = (params) => {
  return async (dispatch) => {
    dispatch({ type: OFFICE_ACTIONS.OFFICE_SAVE_START });
    await axios
      .post("/2.0.0/office", params, {
      })
      .then((response) => {
        dispatch({
          type: OFFICE_ACTIONS.OFFICE_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: OFFICE_ACTIONS.OFFICES_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const modifyOffice = (params) => {
  return async (dispatch) => {
    dispatch({ type: OFFICE_ACTIONS.OFFICE_SAVE_START });
    await axios
      .post("/2.0.0/office/modify", params, {
      })
      .then((response) => {
        dispatch({
          type: OFFICE_ACTIONS.OFFICE_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: OFFICE_ACTIONS.OFFICES_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getOneOfficeById = (officeId) => {
  return async (dispatch) => {
    dispatch({ type: OFFICE_ACTIONS.OFFICE_LOADING });
    await axios
      .get("/2.0.0/office/" + officeId, {
      })
      .then((response) => {
        let data = response.data;
        data.office = [data.office];
        dispatch({
          type: OFFICE_ACTIONS.OFFICE_LOADED,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: OFFICE_ACTIONS.OFFICE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const saveDefaultOfficeLanguage = (languageId) => {
  return async () => {
    await Axios.get("/2.0.0/office/default-language", {
      params: { languageId: languageId },
    }).catch((error) => {
      message.error("Hiba az alapértelmezett nyelv mentése közben!", 5);
      console.log(error.message);
    });
  };
};

export const modifyOfficeDesign = (formData) => {
  return async (dispatch) => {
    dispatch({ type: OFFICE_ACTIONS.OFFICE_SAVE_START });
    await axios
      .post("/2.0.0/office/modify-office-design", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        dispatch({ type: OFFICE_ACTIONS.OFFICE_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: OFFICE_ACTIONS.OFFICE_SAVE_ERROR,
          payload: err?.response?.data,
        });
        console.log(err.message);
      });
  };
};

export const saveOfficeText = (params) => {
  return async (dispatch) => {
    dispatch({ type: LANGUAGE_ACTIONS.LANGUAGE_SAVE_START });
    await axios
      .post("/1.0.0/language/office-text", params)
      .then(() => {
        dispatch({ type: LANGUAGE_ACTIONS.LANGUAGE_SAVE_SUCCESS });
      })
      .catch((err) => {
        console.log(err.message);
        dispatch({
          type: LANGUAGE_ACTIONS.LANGUAGE_SAVE_ERROR,
          payload: err.response.data,
        });
        message.error("Hiba a nyelvi szövegek mentése közben!", 5);
      });
  };
};

export const deleteOffice = (id) => {
  return async (dispatch) => {
    dispatch({ type: OFFICE_ACTIONS.OFFICE_DELETE_START });
    await axios
      .delete(`/2.0.0/office/${id}`)
      .then(() => {
        dispatch({ type: OFFICE_ACTIONS.OFFICE_DELETE_SUCCESS });
        message.success("Sikeres törlés!", 5);
      })
      .catch((err) => {
        console.log(err.message);
        dispatch({
          type: OFFICE_ACTIONS.OFFICE_DELETE_ERROR,
          payload: err.response.data,
        });
        message.error("Hiba az iroda törlése közben!", 5);
      });
  };
};

export const getAllOfficeAddresses = (officeId) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.ADDRESSES_LOADING });
    await Axios.get("/1.0.0/address", {
      params: { officeId: officeId },
    })
      .then((response) => {
        dispatch({
          type: COMPANY_ACTIONS.ADDRESSES_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.ADDRESSES_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getAllOfficeElevatorLevels = (officeId) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.ELEVATOR_LEVELS_LOADING });
    await Axios.get("/3.0.0/elevator/level", {
      params: { officeId: officeId },
    })
      .then((response) => {
        dispatch({
          type: COMPANY_ACTIONS.ELEVATOR_LEVELS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.ELEVATOR_LEVELS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getAllCompanyAddresses = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.ADDRESSES_LOADING });
    await Axios.get("/1.0.0/address", {
      params: { companyId: companyId },
    })
      .then((response) => {
        dispatch({
          type: COMPANY_ACTIONS.ADDRESSES_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.ADDRESSES_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};