import { COMPANY_ACTIONS, USERS_ACTIONS } from "../constants/action-types";
import Axios from "axios";
import { message } from "antd";

export const getAllCompanies = () => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANIES_LOADING });
    await Axios.get("/2.0.0/company", {
      //headers: { Authorization: token },
    })
      .then((response) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANIES_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANIES_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getAllCompaniesByOfficeId = (officeId) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANIES_LOADING });
    await Axios.get("/2.0.0/company/by-office/" + officeId, {
    })
      .then((response) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANIES_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANIES_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getAllCompaniesByCompanyId = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANIES_LOADING });
    await Axios.get("/2.0.0/company/by-company-id/" + companyId, {
    })
      .then((response) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANIES_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANIES_ERROR,
          payload: err.response.data,
        });
        console.error(err.message);
      });
  };
};

export const getAllCompaniesForFilter = (officeId) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANIES_FILTER_LOADING });
    await Axios.get("/2.0.0/company/get-for-filter", {
      params: { officeId: officeId }
    })
      .then((response) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANIES_FILTER_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANIES_FILTER_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getOneCompanyById = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_LOADING });
    await Axios.get("/2.0.0/company/" + companyId, {
      //headers: { Authorization: token },
    })
      .then((response) => {
        let data = response.data;
        data.companies = [data.companies];
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_LOADED,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const companyReset = () => {
  return (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_RESET });
  };
};

export const modifyCompanyDesign = (formData) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_START });
    await Axios.post("/1.0.0/company/modify-company-design", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_ERROR,
          payload: err?.response?.data,
        });
        console.log(err.message);
      });
  };
};

export const saveDefaultLanguage = (companyId, languageId) => {
  return async () => {
    await Axios.get("/1.0.0/company/default-language", {
      params: { companyId: companyId, languageId: languageId },
    }).catch((error) => {
      message.error("Hiba az alapértelmezett nyelv mentése közben!", 5);
      console.log(error.message);
    });
  };
};

export const saveCompany = (params) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_START });
    await Axios.post("/2.0.0/company", params)
      .then((response) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const modifyCompany = (companyId, params) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_START });
    await Axios.put("/2.0.0/company/" + companyId, params)
      .then((response) => {
        dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const saveCompanyName = (params) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_START });
    await Axios.post("/2.0.0/company/name", params)
      .then((response) => {
        dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const saveCompanyTimezone = (params) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_START });
    await Axios.post("/1.0.0/company/timezone", params)
      .then((response) => {
        dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};
export const saveCompanyAddress = (params) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_START });
    await Axios.post("/1.0.0/address", params)
      .then((response) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};
export const modifyCompanyAddress = (params) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_START });
    await Axios.put("/1.0.0/address", params)
      .then((response) => {
        dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const deleteCompanyAddress = (id) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_START });
    await Axios.delete("/1.0.0/address/" + id)
      .then((response) => {
        dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_ERROR,
          payload: err?.response?.data,
        });
        console.log(err.message);
      });
  };
};


export const saveCompanyElevatorLevel = (params) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_START });
    await Axios.post("/3.0.0/elevator/level", params)
      .then((response) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};
export const modifyCompanyElevatorLevel = (params) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_START });
    await Axios.put("/3.0.0/elevator/level", params)
      .then((response) => {
        dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const deleteCompanyElevatorLevel = (id) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_START });
    await Axios.delete("/3.0.0/elevator/level/" + id)
      .then((response) => {
        dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_ERROR,
          payload: err?.response?.data,
        });
        console.log(err.message);
      });
  };
};

export const getAllElevatorLevels = (companyId, officeId) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.ELEVATOR_LEVELS_LOADING });
    await Axios.get("/3.0.0/elevator/level", {
      params: { companyId: companyId, officeId: officeId },
    })
      .then((response) => {
        console.log("**** getAllElevatorLevels action ****", response);
        dispatch({
          type: COMPANY_ACTIONS.ELEVATOR_LEVELS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.ELEVATOR_LEVELS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getElevatorLevelById = (elevatorLevelId) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.ELEVATOR_LEVEL_LOADING });
    await Axios.get("/3.0.0/elevator/level/" + elevatorLevelId)
      .then((response) => {
        dispatch({
          type: COMPANY_ACTIONS.ELEVATOR_LEVEL_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.ELEVATOR_LEVEL_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const addUsersToElevatorLevel = (params) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_START });
    await Axios.post("/3.0.0/elevator/level/users/", params)
      .then((response) => {
        dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_ERROR,
          payload: err?.response?.data,
        });
        console.log(err.message);
      });
  };
};

export const getAllAddresses = (companyId, officeId) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.ADDRESSES_LOADING });
    await Axios.get("/1.0.0/address", {
      params: { companyId: companyId, officeId: officeId },
    })
      .then((response) => {
        dispatch({
          type: COMPANY_ACTIONS.ADDRESSES_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.ADDRESSES_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getAddressById = (addressId) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.ADDRESS_LOADING });
    await Axios.get("/1.0.0/address/" + addressId)
      .then((response) => {
        dispatch({
          type: COMPANY_ACTIONS.ADDRESS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.ADDRESS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const addUsersToAddress = (params) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_START });
    await Axios.post("/2.0.0/users/add-user-to-address", params)
      .then((response) => {
        dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_ERROR,
          payload: err?.response?.data,
        });
        console.log(err.message);
      });
  };
};

export const getAllSubscriptions = () => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.SUBSCRIPTIONS_LOADING });
    await Axios.get("/1.0.0/subscriptions", {
      //headers: { Authorization: token },
    })
      .then((response) => {
        dispatch({
          type: COMPANY_ACTIONS.SUBSCRIPTIONS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.SUBSCRIPTIONS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const finishWizard = (userId, companyId, reception, hradmin) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_START });
    await Axios.post("/1.0.0/company/complete-wizard", { reception: reception, hradmin: hradmin }, {
      params: { userId: userId, companyId: companyId },
    })
      .then((response) => {
        dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_ERROR,
          payload: err?.response?.data,
        });
        console.log(err.message);
      });
  };
};

export const banRegApp = (regAppId) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_START });
    await Axios.delete("/1.0.0/regapp/" + regAppId)
      .then((response) => {
        dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_ERROR,
          payload: err?.response?.data,
        });
        console.log(err.message);
      });
  };
};

export const createRegApp = (companyId, officeId) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_START });
    await Axios.get("/1.0.0/regapp/generate", {
      params: { companyId: companyId, officeId: officeId },
    })
      .then((response) => {
        dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_ERROR,
          payload: err?.response?.data,
        });
        console.log(err.message);
      });
  };
};

export const cropImage = (params) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.CROP_IMAGE_START });
    const formData = new FormData()
    formData.append('image', params.image)
    formData.append('cropData', JSON.stringify(params.cropData))
    formData.append('rotation', params.rotation)
    await Axios.post("/2.0.0/company/crop-image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
      responseType: "blob"
    })
      .then((response) => {
        dispatch({ type: COMPANY_ACTIONS.CROP_IMAGE_SUCCESS, payload: response.data });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.CROP_IMAGE_ERROR,
          payload: err?.response?.data,
        });
      });
  };
};

export const addAddressToRegapp = (regAppId, addressId) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_START });
    await Axios.get("/1.0.0/regapp/add-address", {
      params: { appTokenId: regAppId, addressId: addressId },
    })
      .then((response) => {
        dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_ERROR,
          payload: err?.response?.data,
        });
        console.log(err.message);
      });
  };
};

export const activateCompany = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_START });
    await Axios.get("/1.0.0/company/activate-admin/" + companyId)
      .then((response) => {
        dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_ERROR,
          payload: err?.response?.data,
        });
        console.log(err.message);
      });
  };
};
export const deactivateCompany = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_START });
    await Axios.get("/1.0.0/company/deactivate-admin/" + companyId)
      .then((response) => {
        dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_ERROR,
          payload: err?.response?.data,
        });
        console.log(err.message);
      });
  };
};

export const deleteCompany = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_START });
    await Axios.delete("/2.0.0/company/" + companyId)
      .then((response) => {
        dispatch({ type: COMPANY_ACTIONS.COMPANY_SAVE_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_SAVE_ERROR,
          payload: err?.response?.data,
        });
        console.log(err.message);
      });
  };
};

export const getCompanyLimitById = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANY_ANYONIMIZATION_LIMIT_LOADING });
    await Axios.get("/2.0.0/company/" + companyId + "/anonymization-limit", {
      //headers: { Authorization: token },
    })
      .then((response) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_ANYONIMIZATION_LIMIT_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANY_ANYONIMIZATION_LIMIT_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getUsersForRegapps = (token) => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USERS_LOADING });
    await Axios.get(
      "/2.0.0/users/users-for-regapp",
      { params: { token: token } },
      {
        //headers: { Authorization: token },
      }
    )
      .then((response) => {
        dispatch({
          type: USERS_ACTIONS.USERS_LOADED,
          payload: response.data,
        });
        console.log(response.data)
      })
      .catch((err) => {
        dispatch({
          type: USERS_ACTIONS.USERS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getCompaniesByToken = (token) => {
  return async (dispatch) => {
    dispatch({ type: COMPANY_ACTIONS.COMPANIES_LOADING });
    await Axios.get(
      `/2.0.0/company/token/${token}`
    )
      .then((response) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANIES_LOADED,
          payload: response.data
        });
      })
      .catch((err) => {
        dispatch({
          type: COMPANY_ACTIONS.COMPANIES_ERROR,
          payload: err.response.data,
        });
      });
  };
};